// Start off by setting the webpack public path and nonce. This sets the location where all lazy assets will be loaded from
require('./context-parts/webpack.nonce.js');
require('./context-parts/webpack.public-path.js');

require('@nn/psdl-plugins/plugins.js');
require('./context-parts/context.modules.js');
require('./context-parts/context.nn-responsive.advisor.js');
require('./context-parts/context.nn-responsive.js');

// Loaded last to ensure correct order for legacy purposes
require('@nn/psdl-legacy/src/vap/index.js');
