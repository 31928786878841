/* eslint-disable */
nn.spin = {};
nn.spin.api = {};
nn.spin.object = {};
nn.spin.classes = {};
nn.spin.component = {};

nn.eventDispatcher.on('NN:afterInitializeObjects', function(data) {
  nn.initializeComponents(data.rootElement);
});


/** ******************************************************************************** *
 *                                                                                   *
 *                                   C L A S S E S                                   *
 *                                                                                   *
 *  ******************************************************************************** */

/** --------------------------~ THEMED DIALOG  ~---------------------------- */

/**
 * Initializes a new themed dialog.
 *
 * @param {object} options
 *   Dialog options.
 *
 * @param {string} theme
 *   Theme name.
 */

nn.spin.classes.ThemedDialog = function(options, theme) {
  // Call Dialog constructor
  nn.classes.Dialog.call(this, options);

  // Define properties
  this.theme = null;

  // Set theme
  if (theme) {
    this.setTheme(theme);
  }
};

/**
 * Inherit from Dialog.
 */

nn.spin.classes.ThemedDialog.prototype = Object.create(nn.classes.Dialog.prototype);

/**
 * ThemedDialog methods.
 */

$.extend(nn.spin.classes.ThemedDialog.prototype, {
  getTheme: function() {
    return this.theme;
  },

  setTheme: function(theme) {
    this.unsetTheme();

    if (theme) {
      this.window.addClass('dialog--' + theme);

      if (this.root.hasClass('dialog-shadow')) {
        this.root.addClass('dialog-shadow--' + theme)
      }

      this.theme = theme;
    }
  },

  unsetTheme: function() {
    if (this.theme) {
      this.window.removeClass('dialog--' + this.theme);

      if (this.root.hasClass('dialog-shadow')) {
        this.root.removeClass('dialog-shadow--' + this.theme)
      }
    }

    this.theme = null;
  }
});

/** ******************************************************************************** *
 *                                                                                   *
 *                                C O M P O N E N T S                                *
 *                                                                                   *
 *  ******************************************************************************** */

/** ------------~ A U T O S U G G E S T  T E X T  F I E L D  ~------------- */

/**
 * Initializes a new autosuggest text field component.
 *
 * @param {element} element
 *   The component's root element.
 */

nn.spin.component.AutosuggestTextField = function(element) {
  this.element = $(element);

  // Define properties
  this.open = false;
  this.cache = {};
  this.queryDelayTimeout = null;
  this.lastPreOnCompleteCallback = null;

  // Define option properties
  this.useCache = true;
  this.queryDelay = 0;
  this.maxResults = 0;
  this.minQueryLength = 1;
  this.viewAllButtonText = 'Bekijk alle resultaten met \'{{keyword}}\'';

  // Define element properties
  this.textField = null;
  this.customTextField = null;
  this.viewAllButton = null;
  this.suggestionList = null;
  this.suggestionPanel = null;

  // Initialize
  this.init();
};

/**
 * Inherit from EventDispatcher
 */

nn.spin.component.AutosuggestTextField.prototype = Object.create(nn.core.EventDispatcher.prototype);

/**
 * Tab methods
 */

$.extend(nn.spin.component.AutosuggestTextField.prototype, {
  /**
	 * Initialize the AutosuggestTextField component.
	 */
  init: function() {
    this.getAndSetOptions();
    this.getAndSetElements();
    this.renderUI();
    this.bindEvents();
  },

  /**
	 * Gets and sets the already existing DOM elements that we need.
	 */
  getAndSetElements: function() {
    this.textField = $('input', this.element).first();
    this.textField.attr('autocomplete', 'off');

    this.customTextField = $('.custom-text-field', this.element).first();
  },

  /**
	 * Gets and sets data attribute configuration options.
	 */
  getAndSetOptions: function() {
    if (this.element.attr('data-viewAllButtonText')) {
      this.viewAllButtonText = this.element.attr('data-viewAllButtonText');
    }
  },

  /**
	 * Renders the UI elements.
	 */
  renderUI: function() {
    this.renderSuggestionPanel();
    this.renderSuggestionList();
    this.renderViewAllButton();
  },

  /**
	 * Renders the suggestion panel if it not already exists.
	 */
  renderSuggestionPanel: function() {
    if ( ! this.suggestionPanel) {
      this.suggestionPanel = $('<div class="autosuggest-text-field-suggestionpanel border--focus"></div>');

      if ($('html').hasClass('ie')) {
        new nn.classes.RoundedCorner(this.suggestionPanel);
      }
    }
  },

  /**
	 * Renders the suggestion list if it not already exists.
	 */
  renderSuggestionList: function() {
    if ( ! this.suggestionList) {
      this.suggestionList = $('<ul class="autosuggest-text-field-suggestionlist"></ul>');

      this.suggestionPanel
        .append(this.suggestionList);
    }
  },

  /**
	 * Renders the view all button if it not already exists.
	 */
  renderViewAllButton: function() {
    var text = this.viewAllButtonText,
      text = text.replace('{{keyword}}', this.textField.val());

    if ( ! this.viewAllButton) {
      this.viewAllButton = $('<a class="autosuggest-text-field-viewall" href="#"></a>');

      this.suggestionList
        .after(this.viewAllButton);
    }

    this.viewAllButton
      .text(text);
  },

  /**
	 * Binds the events needed for this component.
	 */
  bindEvents: function() {
    this.textField
      .keyup($.proxy(this.onKeyUpTextField, this))
    //.keydown($.proxy(this.onKeyDownTextField, this))
      .blur($.proxy(this.onBlurTextField, this));

    this.viewAllButton
      .click($.proxy(this.onClickViewAllButton, this));
    //.hover($.proxy(this.onHoverViewAllButton, this));

    this.suggestionList
      .delegate('li', 'click', $.proxy(this.onClickSuggestion, this));
    //.delegate('li', 'mouseover', $.proxy(this.onMouseOverSuggestion, this));
  },

  /**
	* Mouseover suggestionlist handler. Changes selected suggestion
	*
	*/
  onMouseOverSuggestion: function(e) {
    this.selectionHandler(event);
  },

  onKeyDownTextField: function(e) {
    var keycode = e.which;

    if (keycode === 13) {
      if (this.optionSelected) {
        this.optionSelected.click();
        e.preventDefault();
        return false;
      }
    }
  },

  /**
	 * TextField key up event handler. Gets the suggestions and renders the list.
	 * On up/down arrow key press:  changes  the selected  item from the list.
	 * On space bar/enter press, submit form.
	 *
	 * @param {Object} e The jQuery KeyUp event object.
	 */
  onKeyUpTextField: function(e) {
    //evalualate which key is pressed
    var keycode = e.which;

    if (false && keycode === 40 || keycode === 38) {
      // up or down arrow key is pressed, selection from suggestionlist changes
      this.selectionHandler(e);
    } else if (false && /*keycode ===  32 || */keycode === 13) {
      // Enter key is pressed, result is submitted
      if (this.optionSelected) {
        this.optionSelected.click();
        e.preventDefault();
      }
    } else {
      // any other key is pressed
      var self = this;

      clearTimeout(self.queryDelayTimeout);
      //if seach my client no autocomplete the disable class is set by mijn-zoek component
      if(!this.element.hasClass('disable')){
        self.queryDelayTimeout = setTimeout(function() {
          var query = self.getQuery();

          if ( ! self.open) {
            self.showSuggestionPanel();
          }

          if (query.length >= self.minQueryLength) {
            self.updateSuggestions();
            self.renderViewAllButton();
          }else{
            self.hideSuggestionPanel();
          }
        }, self.queryDelay);
      }

    }

  },

  /**
	* Handles  the event of a mouseover or arrowkeypress on the suggestionlist.
	* Changes the selected item and selected item appearance.
	* Updates text field in  accordance with item selected.
	* @param e event
	*/
  selectionHandler: function(e) {
    var list = $(".autosuggest-text-field-suggestionlist li");
    if (this.optionSelected) {
      this.optionSelected.removeClass("hover");
    }
    //determine the event triggering this function
    if (e.which === 40) {
      //down key
      if (this.optionSelected) {
        next = this.optionSelected.next();
        if(next.length > 0) {
          this.optionSelected = next;
        } else {
          this.optionSelected = list.eq(0);
        }
      } else {
        this.optionSelected = list.eq(0);
      }

    } else if (e.which === 38) {
      //up key
      if(this.optionSelected) {
        next = this.optionSelected.prev();
        if(next.length > 0) {
          this.optionSelected = next;
        } else {
          this.optionSelected = list.last();
        }
      } else {
        this.optionSelected = list.last();
      }
    } else {
      //hover
      this.optionSelected = list.filter(function() {
        return $(this).is(":hover");
      });

    }
    this.optionSelected.addClass('hover');
    //update textfield
    var value = this.optionSelected.text();
    this.textField.val(value);

  },

  /**
	 * viewallbutton hover handler. Removes hover class from suggestionlist, when button is hovered
	 *
	 */
  onHoverViewAllButton: function() {
    if(this.optionSelected) {
      this.optionSelected.removeClass('hover');
    }
  },

  /**
	 * TextField blur event handler. Hides the suggestion list.
	 *
	 * @param {Object} e The jQuery blur event object.
	 */
  onBlurTextField: function(e) {
    var self = this;

    setTimeout(function() {
      if (self.open) {
        self.hideSuggestionPanel();
      }
    }, 200);
  },

  /**
	 * Viewall button click event handler. This method will refocus the
	 * textfield and fire the AutosuggestTextField:Select event.
	 *
	 * @param {Object} e The jQuery Click event object.
	 */
  onClickViewAllButton: function(e) {
    e.preventDefault();

    this.textField
      .focus();

    this.trigger('AutosuggestTextField:Select', {
      query: this.getQuery()
    });
  },

  /**
	 * Suggestion click event handler. This method will refocus the
	 * textfield, insert the clicked suggestion and fires the
	 * AutosuggestTextField:Select event.
	 *
	 * @param {Object} e The jQuery Click event object.
	 */
  onClickSuggestion: function(e) {
    var target = this.optionSelected || $(e.currentTarget),
      value = target.attr('data-value');

    this.textField
      .focus()
      .val(value)
      .change();

    this.trigger('AutosuggestTextField:Select', {
      query: this.getQuery()
    });

    this.optionSelected = undefined; // Prevent the search from remembering a possibly non-existant option
  },

  /**
	 * Shows the suggestion panel.
	 */
  showSuggestionPanel: function() {
    this.element
      .addClass('is-open');

    this.customTextField
      .append(this.suggestionPanel);

    this.open = true;
  },

  /**
	 * Hides the suggestion panel.
	 */
  hideSuggestionPanel: function() {
    this.suggestionPanel
      .detach();

    this.element
      .removeClass('is-open');

    this.renderSuggestions('', []);

    this.open = false;

    this.optionSelected = undefined; // Prevent the search from remembering a possibly non-existant option
  },

  /**
	 * Shows loading state and fires the request to get the new suggestions.
	 * After it received the new suggestions it will render them.
	 */
  updateSuggestions: function() {
    var self = this,
      query = this.getQuery(),
      source = this.getSource();

    this.showLoadingState();

    this.getSuggestions(query, source, function(result) {
      self.hideLoadingState(true);
      self.renderSuggestions(result.query, result.suggestions);

      if (result.metaData && result.metaData.viewAllButtonText) {
        self.setViewAllButtonText(result.metaData.viewAllButtonText);
      }
    });
  },

  /**
	 * Gets suggestions based on query and source. It will return the
	 * results as first parameter of the onCompleteCallback function.
	 *
	 * @param {String} query The search query.
	 * @param {Array|Object|Function} source The suggestion source.
	 * @param {Function} onCompleteCallback The callback function to call
	 * when the suggestions are found.
	 */
  getSuggestions: function(query, source, onCompleteCallback) {
    var self = this,
      preOnCompleteCallback;

    // Check if the query exists in cache
    if (this.useCache && this.cache[query]) {
      onCompleteCallback(this.cache[query]);
      return;
    }

    // Create callback for the delegates
    preOnCompleteCallback = function(result) {
      // Only process the last suggestion callback
      if (preOnCompleteCallback === self.lastPreOnCompleteCallback) {
        if (self.useCache) {
          self.cache[query] = result;
        }

        onCompleteCallback(result);
      }
    };

    // Save reference to the last callback
    self.lastPreOnCompleteCallback = preOnCompleteCallback;

    // Delegate to correct method
    if ($.isArray(source)) {
      this.getSuggestionsByArray(query, source, preOnCompleteCallback);
    }
    else if (typeof source === 'object') {
      this.getSuggestionsByObject(query, source, preOnCompleteCallback);
    }
    else if (typeof source === 'function') {
      this.getSuggestionsByFunction(query, source, preOnCompleteCallback);
    }
  },

  /**
	 * Gets suggestions based on query and array input.
	 *
	 * This method supports 2 types:
	 *
	 * ['value_1', 'value_2', 'value_3']
	 *
	 * Or:
	 *
	 * [
	 *     { label: 'Value 1 label', value: 'value_1'},
	 *     { label: 'Value 2 label', value: 'value_2'},
	 *     { label: 'Value 3 label', value: 'value_3'}
	 * ]
	 *
	 * @param {String} query The search query.
	 * @param {Array} source The suggestion source array.
	 * @param {Function} onCompleteCallback The callback function to call
	 * when the suggestions are found.
	 */
  getSuggestionsByArray: function(query, array, onCompleteCallback) {
    var label = '',
      value = '',
      suggestions = [];

    for (var i = 0, n = array.length; i < n; i++) {
      if (typeof array[i] === 'string') {
        label = array[i];
        value = array[i];
      } else {
        label = array[i].label;
        value = array[i].value;
      }

      if (value.substr(0, query.length).toLowerCase() === query.toLowerCase()) {
        suggestions.push({
          label: label,
          value: value
        });
      }
    }

    onCompleteCallback({
      query: query,
      suggestions: suggestions
    });
  },

  /**
	 * Gets suggestions based on object input:
	 *
	 * {
	 *     'value_1': [
	 *         { label: 'Value 1 label', value: 'value_1'},
	 *         { label: 'Value 2 label', value: 'value_2'}
	 *     ],
	 *     'value_2': [
	 *         { label: 'Value 1 label', value: 'value_1'},
	 *         { label: 'Value 2 label', value: 'value_2'}
	 *     ],
	 *     'value_3': [
	 *         { label: 'Value 1 label', value: 'value_1'},
	 *         { label: 'Value 2 label', value: 'value_2'}
	 *     ],
	 * }
	 *
	 * @param {String} query The search query.
	 * @param {Object} object The suggestion source object.
	 * @param {Function} onCompleteCallback The callback function to call
	 * when the suggestions are found.
	 */
  getSuggestionsByObject: function(query, object, onCompleteCallback) {
    var suggestions = [];

    for (var key in object) {
      if (object.hasOwnProperty(key)) {
        if (key.substr(0, query.length).toLowerCase() === query.toLowerCase()) {
          suggestions = object[key];
        }
      }
    }

    onCompleteCallback({
      query: query,
      suggestions: suggestions
    });
  },

  /**
	 * Gets suggestions based on function input.
	 *
	 * @param {String} query The search query.
	 * @param {Function} func The suggestion source function.
	 * @param {Function} onCompleteCallback The callback function to call
	 * when the suggestions are found.
	 */
  getSuggestionsByFunction: function(query, func, onCompleteCallback) {
    func(query, onCompleteCallback);
  },

  /**
	 * Renders the suggestions into the suggestion list.
	 *
	 * @param {String} query The search query.
	 * @param {Array} suggestions The suggestion.
	 */
  renderSuggestions: function(query, suggestions) {
    var label,
      value,
      suggestion,
      suggestionsHTML = '',
      queryLength = query.length,
      maxResults = this.maxResults || suggestions.length;

    for (var i = 0; i < maxResults; i++) {
      suggestion = suggestions[i];

      if (typeof suggestion === 'string') {
        label = suggestion;
        value = suggestion;
      } else {
        label = suggestion.label;
        value = suggestion.value
      }

      if (label.substr(0, queryLength).toLowerCase() === query.toLowerCase()) {
        suggestionsHTML += '<li data-value="' + value + '"><span class="highlight">' + label.substr(0, queryLength) + '</span>' + label.substr(queryLength) + '</li>';
      } else {
        suggestionsHTML += '<li data-value="' + value + '">' + label + '</li>';
      }
    }

    this.suggestionList
      .html(suggestionsHTML);

    if (suggestionsHTML) {
      this.suggestionList
        .show();
    } else {
      this.suggestionList
        .hide();
    }
  },

  getMaxResults: function() {
    return this.maxResults;
  },

  setMaxResults: function(value) {
    this.maxResults = value;
  },

  getQueryDelay: function() {
    return this.queryDelay;
  },

  setQueryDelay: function(value) {
    this.queryDelay = value;
  },

  getMinQueryLength: function() {
    return this.minQueryLength;
  },

  setMinQueryLength: function(value) {
    this.minQueryLength = value;
  },

  getUseCache: function() {
    return this.useCache;
  },

  setUseCache: function(value) {
    this.useCache = value;
  },

  getSource: function() {
    return this.source;
  },

  setSource: function(source) {
    this.source = source;
  },

  getQuery: function() {
    return this.textField
      .val();
  },

  setQuery: function(query) {
    this.textField
      .val(query)
      .change();
  },

  getViewAllButtonText: function() {
    return this.viewAllButtonText;
  },

  setViewAllButtonText: function(text) {
    this.viewAllButtonText = text;
    this.renderViewAllButton();
  },

  /**
	 * Shows the view all button.
	 */
  showViewAllButton: function() {
    this.suggestionList
      .after(this.viewAllButton);
  },

  /**
	 * Hides the view all button.
	 */
  hideViewAllButton: function() {
    this.viewAllButton
      .detach();
  },

  /**
	 * Shows the loading state.
	 */
  showLoadingState: function() {
    this.element
      .addClass('is-loading');
  },

  /**
	 * Hides the loading state.
	 */
  hideLoadingState: function() {
    this.element
      .removeClass('is-loading');
  },

  /**
	 * Returns the DOM element.
	 */
  getElement: function() {
    return this.element;
  }

});


/** -------------------~ AUTOSUGGEST TEXTFIELD - API  ~--------------------- */

nn.spin.api.AutosuggestTextField = function(id) {
  this.object = nn.spin.object[id];
};

nn.spin.api.AutosuggestTextField.prototype = {
  on: function(events, callback, context) {
    return this.object
      .on(events, callback, context);
  },

  off: function(events, callback, context) {
    return this.object
      .off(events, callback, context);
  },

  getMaxResults: function() {
    return this.object
      .getMaxResults();
  },

  setMaxResults: function(value) {
    return this.object
      .setMaxResults(value);
  },

  getQueryDelay: function() {
    return this.object
      .getQueryDelay();
  },

  setQueryDelay: function(value) {
    return this.object
      .setQueryDelay(value);
  },

  getMinQueryLength: function() {
    return this.object
      .getMinQueryLength();
  },

  setMinQueryLength: function(value) {
    return this.object
      .setMinQueryLength(value);
  },

  getUseCache: function() {
    return this.object
      .getUseCache();
  },

  setUseCache: function(value) {
    return this.object
      .setUseCache(value);
  },

  getSource: function() {
    return this.object
      .getSource();
  },

  setSource: function(source) {
    return this.object
      .setSource(source);
  },

  getQuery: function() {
    return this.object
      .getQuery();
  },

  setQuery: function(query) {
    return this.object
      .setQuery(query);
  },

  getViewAllButtonText: function() {
    return this.object
      .getViewAllButtonText();
  },

  setViewAllButtonText: function(text) {
    return this.object
      .setViewAllButtonText(text);
  },

  showViewAllButton: function() {
    return this.object
      .showViewAllButton();
  },

  hideViewAllButton: function() {
    return this.object
      .hideViewAllButton();
  },

  getElement: function() {
    return this.object
      .getElement();
  }
};

/** --------------~ C O O K I E  F L A S H  M E S S A G E  ~---------------- */

/**
 * This component can be used to display a DOM element a certain number of times.
 *
 * <div id="some-message" data-component="CookieFlashMessage" data-maxViews="3">
 *     ...
 * </div>
 *
 * @param {element} element
 *   The component's root element.
 */

nn.spin.component.CookieFlashMessage = function(element) {
  // Define root element
  this.element = $(element);

  // Define properties
  this.maxViews = 3;
  this.cookieNamePrefix = 'CookieFlashMessage-timesViewed-';

  // Initialize
  this.init();
};

nn.spin.component.CookieFlashMessage.prototype = {
  /**
	 * Initializes the CookieFlashMessage component. This method will increment
	 * the viewing counter and remove the element if needed.
	 */
  init: function() {
    var timesViewed = this.getTimesViewed();

    this.getAndSetOptions();

    if (timesViewed < this.maxViews) {
      this.element.show();
      this.setTimesViewed(timesViewed + 1);
    } else {
      this.element.remove();
    }
  },

  /**
	 * Get and set data attribute configuration options.
	 */
  getAndSetOptions: function() {
    if (this.element.attr('maxViews')) {
      this.maxViews = parseInt(this.element.attr('maxViews'), 10);
    }
  },

  /**
	 * Returns the number of times the element is viewed.
	 *
	 * @return {Number} Number of times the element is viewed.
	 */
  getTimesViewed: function() {
    var cookieName = this.getCookieName(),
      cookieValue = nn.classes.Cookie.get(cookieName),
      timesViewed = parseInt(cookieValue, 10) || 0;

    return timesViewed;
  },

  /**
	 * Sets the number of times the element is viewed.
	 *
	 * @param {Number} The number of times the element is viewed.
	 */
  setTimesViewed: function(timesViewed) {
    var cookieName = this.getCookieName();

    // Set times viewed for 365 days
    nn.classes.Cookie.set(cookieName, timesViewed, 365);
  },

  /**
	 * Returns the cookie name. Uses the element ID to generate an unique name.
	 *
	 * @return {String} The Cookie name.
	 */
  getCookieName: function() {
    return this.cookieNamePrefix + this.element.attr('id');
  }
};

/** ----------~ C O O K I E  F L A S H  M E S S A G E  A P I  ~------------- */

nn.spin.api.CookieFlashMessage = function(id) {
  this.object = nn.spin.object[id];
};

nn.spin.api.CookieFlashMessage.prototype = {
  getTimesViewed: function() {
    return this.object
      .getTimesViewed();
  },

  setTimesViewed: function(timesViewed) {
    return this.object
      .setTimesViewed(timesViewed);
  }
};/** ------------------------~ D A T A  T A B L E  ~------------------------- */

/**
 * Initializes a new data table component.
 *
 * @param {element} element
 *   The component's root element (the toggle button).
 */

nn.spin.component.DataTable = function(element) {
  // Define root element
  this.element = $(element);

  // Initialize
  this.init();
};

nn.spin.component.DataTable.prototype = {
  init: function() {
    this.bindEvents();
  },

  bindEvents: function() {
    this.element
      .delegate('.data-table-expand-title', 'click', $.proxy(this.onClickExpandTitle, this));

    this.element
      .delegate('.data-table-expand-button', 'click', $.proxy(this.onClickExpandButton, this));
  },

  onClickExpandTitle: function(e) {
    this.onClickExpandButton(e);
  },

  onClickExpandButton: function(e) {
    var target = $(e.currentTarget),
      row = target.closest('.data-table-row');

    e.preventDefault();

    this.toggleRow(row);
  },

  toggleRow: function(row) {
    var row = $(row);

    if (row.hasClass('is-expanded')) {
      this.collapseRow(row);
    } else {
      this.expandRow(row);
    }
  },

  expandRow: function(row) {
    $(row)
      .addClass('is-expanded');

    nn.handlers.compat.forceReflow();
  },

  collapseRow: function(row) {
    $(row)
      .removeClass('is-expanded');

    nn.handlers.compat.forceReflow();
  },

  expandAllRows: function() {
    $('.data-table-row', this.element)
      .addClass('is-expanded');

    nn.handlers.compat.forceReflow();
  },

  collapseAllRows: function() {
    $('.data-table-row', this.element)
      .removeClass('is-expanded');

    nn.handlers.compat.forceReflow();
  }
};

/** -------------------~ D A T A  T A B L E  -  A P I  ~-------------------- */

nn.spin.api.DataTable = {
  toggleRow: function(id, row) {
    nn.spin.objects[id]
      .toggleRow(row);
  },

  expandRow: function(id, row) {
    nn.spin.objects[id]
      .expandRow(row);
  },

  collapseRow: function(id, row) {
    nn.spin.objects[id]
      .collapseRow(row);
  },

  expandAllRows: function(id) {
    nn.spin.objects[id]
      .expandAllRows();
  },

  collapseAllRows: function(id) {
    nn.spin.objects[id]
      .collapseAllRows();
  }
};/** --------------------~ ENHANCED TABBED COMPONENT  ~---------------------- */

/**
 * This component can be used in conjunction with the tabbed component. It will
 * also toggle the "active" className on the .tab element, instead of only on
 * the anchor element. This enables more posibilities for CSS styling.
 *
 * <div class="component tabbed-component data-component="EnhancedTabbedComponent">
 *     ...
 * </div>
 *
 * @param {element} element
 *   The component's root element.
 */
nn.spin.component.EnhancedTabbedComponent = function (element) {
  this.element = $(element);
  this.tabList = null;
  this.init();
};

nn.spin.component.EnhancedTabbedComponent.prototype = {
  /**
	 * Initializes the EnhancedTabbedComponent component.
	 */
  init: function() {
    this.findAndSetElements();
    this.bindEvents();
    this.findAndSelectTab();
  },

  /**
	 * Finds and sets the needed DOM elements.
	 */
  findAndSetElements: function() {
    this.tabList = $('.tab-list', this.element);
    this.tabs = $('.tab', this.tabList);
    this.tabButtons = $('a', this.tabList);
  },

  /**
	 * Binds the events needed for this component.
	 */
  bindEvents: function() {
    this.tabButtons.click($.proxy(this.onClickTab, this));
  },

  /**
	 * Handles the tab click event.
	 *
	 * @param {Object} e jQuery click event object.
	 */
  onClickTab: function(e) {
    this.findAndSelectTab();
  },

  /**
	 * Finds the currently active tab, and toggles the "active" className on
	 * the above .tab element.
	 */
  findAndSelectTab: function() {
    this.tabs
      .removeClass('active');

    this.tabButtons
      .filter('.active')
      .closest('.tab')
      .addClass('active');
  }
};/** ----------------~ M U L T I  S E L E C T  F I E L D  ~----------------- */

/**
 * Initializes a new multiselect field component.
 *
 * @param {element} element
 *   The component's root element.
 */

nn.spin.component.MultiSelectField = function(element) {
  this.element = $(element);

  // Initialize
  this.init();
};

$.extend(nn.spin.component.MultiSelectField.prototype, {
  /**
	 * Initialize the MultiSelectField compnent
	 */
  init: function() {
    this.getAndSetOptions();
    this.getAndSetElements();
    this.renderUI();
    this.bindEvents();
  },

  /**
	 * Gets and sets the already existing DOM elements that we need.
	 */
  getAndSetElements: function() {
    this.selectField = $('select', this.element).first().attr("hidden","hidden");
  },

  /**
	 * Gets and sets data attribute configuration options.
	 */
  getAndSetOptions: function() {
  },

  /**
	 * Renders the UI elements.
	 */
  renderUI: function() {
    this.renderOptionList();
  },

  /**
	 * Render the
	 */
  renderOptionList: function() {
    if ( ! this.optionList) {
      var optionList = this.optionList = $('<ul class="multi-select-field-optionlist"></ul>');
      this.selectField.find("option").each(function(i,el){
        optionList.append('<li>'+$(el).text()+'</li>');
      });
      this.element.append(this.optionList);
    }
  },

  /**
	 * Binds the events needed for this component.
	 */
  bindEvents: function() {
    this.optionList
      .click($.proxy(this.onClickOption, this));
  },

  /**
	 * Option click handler. Toggles the clicked option
	 *
	 * @param  {Object} e The jQuery click event object.
	 */
  onClickOption: function(e) {
    var target = $(e.target),
      option = this.selectField.find(":nth-child("+(target.index()+1)+")").get(0);

    if (option.selected) {
      option.selected = false;
      target.removeClass("selected");
    } else {
      option.selected = true;
      target.addClass("selected");
    }
  }
});/** ------------------~ P R O D U C T  O V E R V I E W  ~------------------- */

/**
 * Initializes a new product overview component.
 *
 * @param {element} element
 *   The component's root element.
 */

nn.spin.component.ProductOverview = function(element) {
  // Define root element
  this.element = $(element);

  // Define element properties
  this.filterbox = null;
  this.dataResultsCounter = null;
  this.dataResultsContainer = null;
  this.dataNoResult = '';
  this.inputKeyword = null;
  this.inputAction = null;
  this.inputTargetGroup = null;
  this.inputType = null;
  this.inputShowDetails = null;

  // Define properties
  this.products = [];
  this.inputTypeOptions = [];
  this.filterKeyword = null;
  this.filterAction = null;
  this.filterTargetGroup = null;
  this.filterType = null;

  // Init
  this.init();
};

nn.spin.component.ProductOverview.prototype = {
  init: function() {
    this.dataNoResult = this.element.attr('data-noresult');
    this.findElements();
    this.indexTypeOptions();
    this.indexProducts();
    this.bindEvents();
    this.updateFilters();
    this.updateInputType();
    this.updateProducts();
    this.toggleShowDetails();
  },

  findElements: function() {
    // Find filterbox
    this.filterbox = $('.filterbox', this.element);

    // Find filterbox filter elements
    this.inputKeyword = $('.product-overview-keyword', this.filterbox);
    this.inputAction = $('.product-overview-action2', this.filterbox);
    this.inputTargetGroup = $('.product-overview-target-group', this.filterbox);
    this.inputType = $('.product-overview-type', this.filterbox);
    this.inputShowDetails = $('.product-overview-show-details', this.filterbox);

    //first check if this.inputaction excist for removing unused options
    if(this.inputAction[0] != undefined) {
      this.removeUnusedFilterAction();
    }
    // Find data results counter
    this.dataResultsCounter = $('.data-results-actions-amount dd', this.element);

    // Find data results container
    this.dataResultsContainer = $('.data-results-table-container', this.element);
  },

  indexProducts: function() {
    var row = null,
      rowDetail = null,
      rows = null,
      product = null,
      products = [],
      productName = '',
      includedProducts = null,
      productCounts = [];

    // Find data table rows (products)
    rows = $('.data-table-row', this.dataResultsContainer);

    // Index products
    for (var i = 0, n = rows.length; i < n; i++) {
      row = $(rows[i]);
      rowDetail = row.next('.data-table-row-detail');
      // Create product model
      product = {
        element: row,
        type: row[0].getAttribute('data-type'),
        targetGroup: row[0].getAttribute('data-target-group'),
        action: row.find('*[data-action]'),
        includedProducts: []
      };

      // Add product name to included products
      productName = $('.data-table-expand-title', row)[0]
        .innerHTML
        .toLowerCase();

      product
        .includedProducts
        .push(productName);

      // Add subproducts to included products
      includedProducts = $('.product-overview-subproducts a', rowDetail);

      for (var ii = 0, nn = includedProducts.length; ii < nn; ii++) {
        productName = includedProducts[ii]
          .innerHTML
          .toLowerCase();

        product
          .includedProducts
          .push(productName);
      }

      // Add product to products
      products
        .push(product);

      // Increment counter
      if ( ! productCounts[product.targetGroup]) {
        productCounts[product.targetGroup] = [];
      }
      else if ( ! productCounts[product.targetGroup][product.type]) {
        productCounts[product.targetGroup][product.type] = 1;

        productCounts[product.targetGroup][product.type]++;
      }
    }

    // Set new products index
    this.products = products;

    // Set new products counts
    this.productCounts = productCounts;
  },

  indexTypeOptions: function() {
    var options = [],
      optionElements = this.inputType.children();

    for (var i = 0, n = optionElements.length; i < n; i++) {
      options[optionElements[i].value] = optionElements[i].innerHTML;
    }

    this.inputTypeOptions = options;
  },

  bindEvents: function() {
    this.inputAction
      .change($.proxy(this.onChangeInputAction, this));

    this.inputShowDetails
      .change($.proxy(this.onChangeInputShowDetails, this));

    this.inputType
      .change($.proxy(this.onChangeInputType, this));

    this.inputTargetGroup
      .change($.proxy(this.onChangeInputTargetGroup, this));

    this.inputKeyword
      .keyup($.proxy(this.onKeyupKeyword, this));

    this.dataResultsContainer
      .delegate('.data-table-expand-button, .data-table-expand-title', 'click', $.proxy(this.onClickExpandButton, this));

  },

  updateFilters: function() {
    this.updateFilterType();
    this.updateFilterKeyword();
    this.updateFilterTargetGroup();
    this.updateFilterAction();
  },

  onChangeInputShowDetails: function(e) {
    this.toggleShowDetails();
  },

  toggleShowDetails: function() {
    if (this.inputShowDetails.is(':checked')) {
      this.expandAllProducts();
    } else {
      this.collapseAllProducts();
    }
  },

  expandAllProducts: function() {
    $('.data-table-row', this.dataResultsContainer)
      .addClass('is-expanded');

    nn.handlers.compat.forceReflow();
  },

  collapseAllProducts: function() {
    $('.data-table-row', this.dataResultsContainer)
      .removeClass('is-expanded');

    nn.handlers.compat.forceReflow();
  },

  onClickExpandButton: function(e) {
    e.preventDefault();
    this.updateInputShowDetailsState();
  },

  updateInputShowDetailsState: function() {
    var rows = $('.data-table-row', this.dataResultsContainer),
      expandedRows = rows.filter('.is-expanded');

    if (expandedRows.length === rows.length) {
      this.inputShowDetails
        .attr('checked', 'checked');
    } else {
      this.inputShowDetails
        .removeAttr('checked');
    }
  },
  onChangeInputAction: function(e) {
    this.updateFilterAction();
    this.updateProducts();

  },
  onChangeInputType: function(e) {
    this.updateFilterType();
    this.updateProducts();
  },

  updateFilterType: function() {
    var value = this.inputType.val();
    this.setFilterType(value);
  },

  setFilterType: function(type) {
    this.filterType = (type !== '') ? type : null;
  },

  updateInputType: function() {
    var productCounts = this.productCounts,
      selectedOption = this.inputType.val(),
      selectField = null,
      newSelectField = null;

    // Find current type select field
    selectField = this.inputType.parents('.select-field');

    // Create new type select field
    newSelectField = selectField.clone();
    newSelectField.find('[data-initialized="true"]').removeAttr('data-initialized');

    // Replace current type select field with new field
    selectField.replaceWith(newSelectField);

    // Set new type input element and remove all items but the first
    this.inputType = $('.product-overview-type', newSelectField);
    this.inputType.children(':gt(0)').remove();

    if (this.filterTargetGroup === null) {
      for (var targetGroup in productCounts) {
        if (productCounts.hasOwnProperty(targetGroup)) {
          for (var type in productCounts[targetGroup]) {
            if (productCounts[targetGroup].hasOwnProperty(type) && this.inputTypeOptions[type] && ! this.inputType.children('option[value="' + type + '"]').length) {
              this.inputType.append('<option value="' + type + '">' + this.inputTypeOptions[type] + '</option>')
            }
          }
        }
      }
    } else {
      for (var type in productCounts[this.filterTargetGroup]) {
        if (productCounts[this.filterTargetGroup].hasOwnProperty(type) && this.inputTypeOptions[type]) {
          this.inputType.append('<option value="' + type + '">' + this.inputTypeOptions[type] + '</option>')
        }
      }
    }

    // Try to set the previous selected option
    if (selectedOption !== '') {
      // Check if the previous selected options still exists
      selectedOption = this.inputType.children('[value="' + selectedOption + '"]');

      if (selectedOption.length) {
        // Set value
        this.inputType.val(selectedOption.attr('value'));
      }
    }

    // Rebind input type change handler and fire change event
    this.inputType
      .attr('data-component','Select')
      .change($.proxy(this.onChangeInputType, this))
      .change();
    nn.initializeComponents(this.inputType.parent());

  },

  onChangeInputTargetGroup: function(e) {
    this.updateFilterTargetGroup();
    this.updateInputType();
    this.updateProducts();
  },

  updateFilterTargetGroup: function() {
    var value = this.inputTargetGroup.val();
    this.setFilterTargetGroup(value);
  },

  setFilterTargetGroup: function(targetGroup) {
    this.filterTargetGroup = (targetGroup !== '') ? targetGroup : null;
  },

  removeUnusedFilterAction: function() {
    var arrOptionsToRemove = [];
    var selectboxAction = this.inputAction[0];
    var actionOPtions = selectboxAction.options;
    n = actionOPtions.length;
    for (var i = 0; i < n; i++){
      filterOption = actionOPtions[i].value;
      if($('[data-action="'+filterOption+'"]').length === 0){
        arrOptionsToRemove.push(filterOption);
      }
    }
    for(var j=0; j < n; j++){
      for(var k=0; k < arrOptionsToRemove.length; k++){
        if(actionOPtions[j] != undefined){
          if(actionOPtions[j].value == arrOptionsToRemove[k]){
            $(actionOPtions[j]).remove();
          }
        }
      }
    }
    this.inputAction.attr('data-component','Select');
    nn.initializeComponents(this.inputAction.parent().addClass('select-field'));
  },

  updateFilterAction: function() {
    var value = this.inputAction.val();
    this.setFilterAction(value);
  },

  setFilterAction: function(action) {
    this.filterAction = (action !== '') ? action : null;
  },

  onKeyupKeyword: function(e) {
    this.updateFilterKeyword();
    this.updateProducts();
  },

  updateFilterKeyword: function() {
    var value = this.inputKeyword.val();
    this.setFilterKeyword(value);
  },

  setFilterKeyword: function(keyword) {
    this.filterKeyword = (keyword !== '') ? keyword : null;
  },

  updateProducts: function() {
    var matches = null,
      counter = this.dataResultsCounter,
      container = this.dataResultsContainer;

    // Get filtered products
    matches = this.filterProducts(this.products);
    // Set element height to prevent document skipping
    this.element
      .css('height', this.element.height() + 'px');

    // Hide container for render performance
    container
      .hide();

    // Hide all data tables and product headings
    $('.data-table,' +
		  '.data-results-category,' +
		  '.data-results-subcategory', container)
      .hide();

    // Hide all products
    $('.data-table-row', container)
      .addClass('is-hidden');

    // Hide all action buttons
    $('*[data-action]', container)
      .addClass('is-hidden');

    // Unhide matching products
    matches
      .removeClass('is-hidden');

    // Show active action buttons
    $('[data-action="'+this.filterAction+'"]', matches)
      .removeClass('is-hidden');

    // Unhide matching data tables and product headings
    matches
      .closest('.data-table')
      .show()
      .prev('.data-results-subcategory')
      .show()
      .prev('.data-results-category')
      .show();
    // Show container for render performance
    container
      .show();

    // Unset element height to prevent document skipping
    this.element
      .css('height', 'auto');

    // Update result counter
    counter
      .text(matches.length + ' producten');

    //if matches.length == 0 show dataNoResult
    if (matches.length === 0 && !this.dataNoResultShown){
      this.dataResultsContainer.append('<p class="dataNoResult">'+ this.dataNoResult +'</p>');
      this.dataNoResultShown = true;
    } else if (matches.length > 0 && this.dataNoResultShown) {
      this.dataResultsContainer.find('.dataNoResult').remove();
      this.dataNoResultShown = false;
    }

    // Force reflow
    nn.handlers.compat.forceReflow();
  },

  filterProducts: function(products) {
    var matches = $(),
      product = null,
      filterType = this.filterType,
      filterTargetGroup = this.filterTargetGroup,
      filterKeyword = this.filterKeyword;

    // Filter products
    for (var i = 0, n = products.length; i < n; i++) {
      product = products[i];

      // Filter on type
      if (filterType !== null && product.type !== filterType) {
        continue;
      }

      // Filter on target group
      if (filterTargetGroup !== null && product.targetGroup !== filterTargetGroup) {
        continue;
      }

      // Filter on keyword
      if (filterKeyword !== null) {
        filterKeyword = filterKeyword.toLowerCase();

        for (var ii = 0, nn = product.includedProducts.length; ii < nn; ii++) {
          if (product.includedProducts[ii].indexOf(filterKeyword) !== -1) {
            break;
          }
        }

        // No match?
        if (ii === nn) {
          continue;
        }
      }

      // Add product to matches
      matches
        .push(product.element[0]);
    }
    return matches;
  }
};/** -----------------~ S C R O L L  T O P  B U T T O N  ~------------------- */

/**
 * Initializes a new scroll top button component.
 *
 * @param {element} element
 *   The component's root element (the toggle button).
 */

nn.spin.component.ScrollTopButton = function(element) {
  // Define root element
  this.element = $(element);

  // Define properties
  this.fixed = false;
  this.visible = false;
  this.positionLeft = null;
  this.scrollTimeout = null;

  // Define element properties
  this.window = null;
  this.wrapper = null;
  this.buttonContainer = null;

  // Define options
  this.buttonContainerSelector = null;

  // Initialize
  this.init();
};

nn.spin.component.ScrollTopButton.prototype = {
  init: function() {
    this.getAndSetOptions();
    this.findElements();
    this.renderWrapper();

    this.updateVisibility();
    this.updatePosition();

    this.bindEvents();
  },

  getAndSetOptions: function() {
    if (this.element.attr('data-button-container-selector')) {
      this.buttonContainerSelector = this.element.attr('data-button-container-selector');
    }
  },

  findElements: function() {
    this.window = $(window);

    if (this.buttonContainerSelector) {
      this.buttonContainer = $(this.buttonContainerSelector);
    } else {
      this.buttonContainer = this.element.parent();
    }
  },

  renderWrapper: function() {
    if ( ! this.wrapper) {
      this.wrapper = $('<div class="scroll-top-button-wrapper"></div>');

      this.wrapper
        .hide()
        .append(this.element)
        .appendTo(this.buttonContainer);
    }
  },

  bindEvents: function() {
    this.element
      .bind('click', $.proxy(this.onClickElement, this));

    this.window
      .bind('resize scroll', $.proxy(this.onResizeOrScrollWindow, this));
  },

  onClickElement: function(e) {
    e.preventDefault();

    $('html, body').animate({
      scrollTop: 0
    }, 800);
  },

  onResizeOrScrollWindow: function(e) {
    if ( ! this.scrollTimeout) {
      var self = this;

      this.scrollTimeout = setTimeout(function() {
        self.updatePosition();
        self.updateVisibility();
        self.scrollTimeout = null;
      }, 20);
    }
  },

  updatePosition: function() {
    var posWindowBottom = this.window.scrollTop() + this.window.height(),
      posOuterBoxBottom = this.buttonContainer.offset().top + this.buttonContainer.innerHeight();

    if (posOuterBoxBottom < posWindowBottom) {
      this.updatePositionAbsolute();
    } else {
      this.updatePositionFixed();
    }
  },

  updatePositionAbsolute: function() {
    if (this.fixed) {
      this.wrapper.css({
        margin: '0 0 40px 0',
        position: 'fixed',
        right: 'auto',
        bottom: '0px',
        left: positionLeft + 'px'
      });

      this.fixed = false;
      this.positionLeft = null;
    }
  },

  updatePositionFixed: function() {
    var left = this.buttonContainer.offset().left + this.buttonContainer.innerWidth() - this.wrapper.innerWidth();
    positionLeft = left;

    if ( ! this.fixed) {
      this.wrapper.css({
        position: 'fixed',
        right: 'auto',
        bottom: '0px',
        left: '0px'
      });

      this.fixed = true;
    }

    if (positionLeft !== this.positionLeft) {
      this.wrapper
        .css('left', positionLeft + 'px');

      this.positionLeft = positionLeft;
    }
  },

  updateVisibility: function() {
    if (this.window.scrollTop() > 240) {
      if(!this.visible){
        this.wrapper.stop(true, true).fadeIn();
        this.visible = true;
      }
    }else if(this.visible){
      this.wrapper.stop(true, true).fadeOut();
      this.visible = false;
    }
  }
};
