
(function (window, $, nn) {

  nn.component.SearchAdvisor = function (element) {
    this.element = $(element);

    this.init();
  };

  nn.component.SearchAdvisor.prototype = {
    init: function () {
      this.getAndSetElements();
      this.bindEvents();
      this.checkInitialSwitch();
    },
    getAndSetElements: function () {
      this.radioSwitches = this.element.find('[data-search-role="search-switch"]');
      this.radioSwitchName = this.radioSwitches.prop("name");
      this.autoSuggestField = this.element.find('[data-component*=TextFieldAutosuggest]');
    },
    bindEvents: function () {
      if (this.radioSwitches.length > 0 && this.autoSuggestField.length > 0) {
        this.radioSwitches.on('change', $.proxy(this.onRadioSwitchChange, this));
      }
    },
    onRadioSwitchChange: function (evt) {
      this.switchSearch(evt.target);
    },
    switchSearch: function(radioSwitch) {
      radioSwitch = this.radioSwitches.filter(radioSwitch);

      this.autoSuggestField.data('autosuggest-disabled',!radioSwitch.data('search-autosuggest'));
      this.autoSuggestField.prop('placeholder',radioSwitch.data('search-placeholder-text'));
      this.element.prop('action',radioSwitch.data('search-form-action'));
    },
    checkInitialSwitch: function() {
      var initialSwitch = this.radioSwitches.filter(':checked');
      if (initialSwitch.length > 0) {
        this.switchSearch(initialSwitch);
      }
    }
  };
})(window, jQuery, nn);