/**
 * The below imported modules are the js files specific for Advisor.
 * These are all the files that are in
 *     - advisor.config.json[scripts.path]
 * but not in
 *     - nn.config.json[scripts.paths]
 *
 * Any file appearing only in nn.config.json[scripts.paths] is placed in context.nn-responsive.nn.js
 * All files appearing both in advisor.config.json[scripts.path] and nn.config.json[scripts.paths] are placed in context.nn-responsive.js
 */

require('@nn/psdl-legacy/src/nn-responsive/global/js/rangeslider.min.js');
require('@nn/psdl-legacy/src/nn-responsive/component/search-advisor/js/SearchAdvisor.js');
